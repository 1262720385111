import React, { useMemo, useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LinkIcon from '@mui/icons-material/Launch'
import KIChat from './gpt'
import Markdown from 'react-markdown'
import { KnowledgeArticle } from '../services'
import remarkGfm from 'remark-gfm'
import { TableOfContents } from './TableOfContents'
import { SnomedConcept } from '../interfaces/Concept.interface'
import CopyAll from '@mui/icons-material/CopyAll'

interface CardProps {
  header: React.ReactNode;
  content: React.ReactNode;
  tag?: string;
  onClick: () => void;
  extraCssClasses?: string;
  icon?: string;
  isChecked?: boolean; // Make optional by adding ?
  onCheck?: () => void; // Make optional by adding ?
}

const Card: React.FC<CardProps> = ({
  header,
  tag,
  content,
  onClick,
  extraCssClasses,
  icon,
  isChecked,
  onCheck,
}) => {
  return (
    <div
      className={
        'card clickable' + (extraCssClasses ? ' ' + extraCssClasses : '')
      }
      onClick={() => onClick()}
    >
      <div className="row small-gap">
        {isChecked !== undefined && onCheck && ( // Conditionally render the checkbox
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => {
              e.stopPropagation(); // Prevent checkbox from triggering card click
              onCheck();           // Call onCheck to toggle selection
            }}
          />
        )}
        {icon && (
          <div>
            <img className={'source-icon'} src={icon} width={32} height={32} alt="" />
          </div>
        )}
        <div className="column flex1">
          <div className={'row space-between'}>
            {header}
            {tag && <div className={'tag'}>{tag}</div>}
          </div>
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
};



interface SearchResultsProps {
  articles: KnowledgeArticle[];
  selectedOwners?: String[];
  isLoading: boolean;
  term: string;
  snomedConcept?: SnomedConcept;
  useIcons?: boolean;
  onArticleChange: (text: KnowledgeArticle | null) => void;
  chatResetKey: number; // Add this line
}

export const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const [selectedArticleId, setSelectedArticleId] = useState<string | null>(null);
  const [kiTriggered, setKiTriggered] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState<string[]>([]); // Track selected articles

  const selectedArticle = props.articles.find(
    (source) => source.id === selectedArticleId
  );

  const unselectArticle = () => {
    setSelectedArticleId(null);
    props.onArticleChange(null);
  };

  const selectArticle = (id: string) => {
    setSelectedArticleId(id);
    const article = props.articles.find((source) => source.id === id);
    if (article) {
      props.onArticleChange(article);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (props.term && !props.isLoading) {
      setKiTriggered(true);
    }
  }, [props.term, props.isLoading]);

  const toggleArticleSelection = (id: string) => { // Toggle selection function
    setSelectedArticles((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((articleId) => articleId !== id)
        : [...prevSelected, id]
    );
  };

  const isArticleSelected = (id: string) => selectedArticles.includes(id); // Helper function

  const groupAndSortArticles = (
    articles: KnowledgeArticle[],
    selectedArticleId: string | null
  ) => {
    const grouped = articles
      .filter((article) => article.id !== selectedArticleId)
      .reduce((acc, article) => {
        const key =
          article.infoType !== 'Pasientinformasjon' ? '' : article.infoType;
        acc[key] = acc[key] || [];
        acc[key].push(article);
        return acc;
      }, {} as Record<string, KnowledgeArticle[]>);

    return Object.entries(grouped).sort(([a], [b]) => {
      if (a === '') return -1;
      if (b === '') return 1;
      return a.localeCompare(b);
    });
  };

  const groupedArticles = useMemo(
    () => groupAndSortArticles(props.articles, selectedArticleId),
    [props.articles, selectedArticleId]
  );

  return (
    <div className={'padded'}>
      {selectedArticle && (
        <>
          <button
            onClick={unselectArticle}
            className={
              'flat padded row small-gap no-margin-bottom align-center'
            }
          >
            <ArrowBackIcon fontSize={'small'} />
            <span>Tilbake</span>
          </button>
          <Article article={selectedArticle} />
        </>
      )}

      {!selectedArticle && (
        <div className="ki-chat" style={{ marginBottom: '20px' }}>
            <KIChat key={props.chatResetKey} term={props.term} />
        </div>
      )}

      <div className={'break-word column gap padded'}>
        {selectedArticle ? (
          props.articles.length >= 2 ? (
            <h2>Øvrige treff:</h2>
          ) : null
        ) : (
          props.articles.length >= 1 && (
            <div style={{ marginTop: '20px' }}> {/* Added margin for separation */}
              VAR-prosedyrer for <strong>{props.term}</strong>{' '}
              {props.snomedConcept?.id && (
                <small className={'muted-text'}>
                  ({props.snomedConcept.id})
                </small>
              )}
            </div>
          )
        )}

        {groupedArticles.map(([infoType, articles]) => (
          <div key={infoType} className={'column gap'}>
            {infoType && <h3>{infoType}</h3>}
            {articles.map((sourceResponse) => {
              const isArticleLinkOnly =
                sourceResponse.link !== '' &&
                sourceResponse.content.text === '' &&
                sourceResponse.content.intro === '' &&
                sourceResponse.content.practical === '' &&
                sourceResponse.content.rationale === '';

              return (
                <div className="row small-gap align-center" key={sourceResponse.id} style={{ width: '100%' }}>
                  <input
                    type="checkbox"
                    checked={isArticleSelected(sourceResponse.id)}
                    onChange={() => toggleArticleSelection(sourceResponse.id)}
                  />
                  <div style={{ flex: 1 }}>
                    <Card
                      onClick={() =>
                        isArticleLinkOnly
                          ? window.open(sourceResponse.link, '_blank')
                          : selectArticle(sourceResponse.id)
                      }
                      header={
                        <div
                          className={
                            'row space-between owner-text align-center tiny-gap'
                          }
                        >
                          <span>{sourceResponse.owner}</span>
                          {isArticleLinkOnly ? (
                            <LinkIcon fontSize={'small'} />
                          ) : null}
                        </div>
                      }
                      content={sourceResponse.title}
                      tag={sourceResponse.infoType}
                      icon={
                        props.useIcons
                          ? iconFromOwner(sourceResponse.owner[0] || '')
                          : undefined
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ))}
        {props.isLoading && <PlaceholderLoaderArticles />}
      </div>

      {/* Conditional rendering of the "Kopier utvalg" button */}
      {props.articles.length >= 1 && (
        <div className="row gap" style={{ marginTop: '20px' , marginLeft: '15px' , display: 'flex', justifyContent: 'flex-start' }}>
          <button className="flat-button row align-center small-gap" onClick={() => {}}>
            <span>Kopier utvalg</span>
            <CopyAll />
          </button>
        </div>
      )}
    </div>
  );
};




const iconFromOwner = (owner: string): string => {
  switch (owner) {
    case 'Vestre Viken':
    case 'VestreViken':
      return 'assets/source-icons/vestreviken.ico'
    case 'Helsedirektoratet':
      return 'assets/source-icons/hdir.png'
    case 'Felleskatalogen':
      return 'assets/source-icons/fk.png'
    case 'BMJ':
    case 'Bmj':
    case 'bmj':
      return 'assets/source-icons/bmj.png'
    case 'VAR':
    case 'Var':
    case 'Varnett':
      return 'assets/source-icons/varnett.png'
    case 'NEL':
    case 'nel':
      return 'assets/source-icons/nel.png'
    case 'Mattilsynet':
      return 'assets/source-icons/mattilsynet.png'
    default:
      return ''
  }
}

const Article: React.FC<{ article: KnowledgeArticle }> = ({ article }) => {
  const textToId = (str: string) => {
    return str
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/æ/g, 'ae')
      .replace(/ø/g, 'oe')
      .replace(/å/g, 'aa')
      .replace(/[^a-z0-9-]/g, '')
  }

  const getTextFromChildren = (children: any): string => {
    if (typeof children === 'string') {
      return children
    }

    if (Array.isArray(children)) {
      for (let i = 0; i < children.length; i++) {
        const text = getTextFromChildren(children[i])
        if (text !== '') {
          return text
        }
      }
      return ''
    }

    return children && children.props
      ? getTextFromChildren(children.props.children)
      : ''
  }

  return (
    <article>
      <h1>{article.shortTitle || article.title}</h1>
      {article.professionalUpdate && (
        <div className="align-left">
          Siste faglig oppdatert: {article.professionalUpdate}
        </div>
      )}
      {article.productTitle !== undefined ||
      article.productUrl !== undefined ? (
        <div className="align-left">
          Hentet fra:{' '}
          <a href={article.productUrl} target="_blank" rel="noreferrer">
            {article.productTitle}
          </a>
        </div>
      ) : (
        ''
      )}
      {article.content && (
        <>
          <TableOfContents
            markdown={article.content.text || ''}
            textToId={textToId}
            extraChapters={[
              ...(article.content.practical !== ''
                ? [
                    {
                      title: 'Praktisk - slik kan anbefalingen følges',
                      id: 'article-h2-praktisk',
                    },
                  ]
                : []),
              ...(article.content.rationale !== ''
                ? [
                    {
                      title: 'Begrunnelse - dette er anbefalingen basert på',
                      id: 'article-h2-rationale',
                    },
                  ]
                : []),
            ]}
          />
          <Markdown>{article.content.intro}</Markdown>
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              h2(props: any) {
                return (
                  <h2
                    id={
                      props.children
                        ? textToId(getTextFromChildren(props.children))
                        : ''
                    }
                  >
                    {props.children}
                  </h2>
                )
              },
              table(props: any) {
                return (
                  <div className="table-container">
                    <table>{props.children}</table>
                  </div>
                )
              },
            }}
          >
            {article.content.text}
          </Markdown>

          {article.content.practical !== '' && (
            <>
              <h2 id={'article-h2-praktisk'}>
                Praktisk - slik kan anbefalingen følges
              </h2>
              <Markdown>{article.content.practical}</Markdown>
            </>
          )}
          {article.content.rationale !== '' && (
            <>
              <h2 id={'article-h2-rationale'}>
                Begrunnelse - dette er anbefalingen basert på
              </h2>
              <Markdown>{article.content.rationale}</Markdown>
            </>
          )}
        </>
      )}
      <br />
      {article.link && (
        <a
          className="align-left"
          href={article.link}
          target="_blank"
          rel="noreferrer"
        >
          {article.link}
        </a>
      )}
    </article>
  )
}

// 3. Update PlaceholderLoaderArticles to include isChecked and onCheck props
const PlaceholderLoaderArticles: React.FC = () => (
  <>
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}>&nbsp;</span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background'}
    />
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}>&nbsp;</span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background opacity-05'}
    />
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}>&nbsp;</span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background opacity-02'}
    />
  </>
);