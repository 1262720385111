// gpt.jsx

import React, { useEffect, useState, useRef } from 'react'
import { createThread, sendMessage } from './apigpt'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CopyAll from '@mui/icons-material/CopyAll'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import '../index.css'

const KIChat = ({ term }) => {
  const [chat, setChat] = useState([])
  const [loading, setLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [threadId, setThreadId] = useState(null)
  const endOfMessagesRef = useRef(null)
  const [userMessage, setUserMessage] = useState('')

  // Custom components for handling checkboxes in Markdown
  const customComponents = {
    input: ({ node, ...props }) => (
      <input
        type="checkbox"
        defaultChecked={node.checked}
        style={{ marginRight: '5px' }}
        onClick={(e) => e.stopPropagation()}
      />
    ),
    a: ({ node, children, ...props }) => (
      <a className={'row tiny-gap align-center'} {...props}>
        <ExpandMoreIcon /> {children}
      </a>
    ),
    h3: ({ node, children, ...props }) => {
      const randomId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      return (
        <h3>
          <input
            id={randomId}
            type="checkbox"
            defaultChecked={false}
            style={{ marginRight: '5px' }}
            onClick={(e) => e.stopPropagation()}
          />
          <label for={randomId} style={{ cursor: 'pointer' }} {...props}>
            {children}
          </label>
        </h3>
      )
    },
  }

  // Function to handle streaming of messages
  const handleStreamResponse = async (threadId, messageContent) => {
    setLoading(true)

    try {
      const stream = await sendMessage(threadId, messageContent)
      const reader = stream.getReader()
      let currentText = ''

      const read = async () => {
        const { done, value } = await reader.read()
        if (done) {
          setLoading(false)
          setIsGenerating(false)
          endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' }) // Scroll only once at the end
          return
        } else {
          if (!isGenerating) setIsGenerating(true)
        }

        const chunk = new TextDecoder().decode(value)
        currentText += chunk
        setChat((prevChat) => {
          const lastMessage = prevChat[prevChat.length - 1]
          if (lastMessage && lastMessage.isBot) {
            return [...prevChat.slice(0, -1), { isBot: true, msg: currentText }]
          } else {
            return [...prevChat, { isBot: true, msg: currentText }]
          }
        })
        await read()
      }

      await read()
    } catch (error) {
      console.error('Error in streaming response:', error)
      setLoading(false)
      setIsGenerating(false)
    }
  }

  // Initialize the chat with the search term
  useEffect(() => {
    const initializeChat = async () => {
      if (term) {
        try {
          const newThreadId = await createThread()
          setThreadId(newThreadId)
          await handleStreamResponse(
            newThreadId,
            `Lag en pleieplan basert på dette snomedbegrepet ${term}`,
          )
        } catch (error) {
          console.error('Failed to initialize chat:', error)
        }
      }
    }

    initializeChat()
  }, [term])

  // Scroll to the end of messages when a new message completes
  useEffect(() => {
    if (!loading && isGenerating === false) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chat, loading, isGenerating])

  // Function to send user message
  const handleSendMessage = async () => {
    if (!userMessage.trim()) return

    setChat((prevChat) => [...prevChat, { isBot: false, msg: userMessage }])
    setUserMessage('')

    if (threadId) {
      await handleStreamResponse(threadId, userMessage)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <div className="ki-chat">
      <div className="chat-history column gap">
        {chat.map((message, index) => (
          <div
            key={index}
            className={message.isBot ? 'bot-message' : 'user-message'}
          >
            <Markdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={customComponents}
            >
              {message.msg}
            </Markdown>
            {message.isBot && (
              <div class={'row gap'}>
                <button className={'flat-button row align-center small-gap'}>
                  <span>Kopier utvalg</span>
                  <CopyAll />
                </button>
                <button className={'flat-button row align-center small-gap'}>
                  <span>Kopier alt</span>
                  <CopyAll />
                </button>
              </div>
            )}
          </div>
        ))}
        {loading && !isGenerating && (
          <div className="loader">
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
          </div>
        )}
        <div ref={endOfMessagesRef} />
      </div>
      {!isGenerating && !loading && (
        <div className="chat-input-container">
          <textarea
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Skriv din melding her..."
            className="chat-textarea"
          />
          <button
            onClick={handleSendMessage}
            className="send-button"
            disabled={isGenerating || loading || !userMessage.trim()}
          >
            Send
          </button>
        </div>
      )}
    </div>
  )
}

export default KIChat
