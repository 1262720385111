import felleskatalogen from './felleskatalogen'
import vestreviken from './vestreviken'
import hdir from './hdir'
import infobutton from './infobutton'
import demo from './demo'
import helseplattformen from './helseplattformen'
import spl from './spl'

const configs = {
  felleskatalogen,
  vestreviken,
  hdir,
  demo,
  infobutton,
  helseplattformen,
  spl,
}

export default configs
