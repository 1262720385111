//Snomwstorm server core parameters
export const terminlogyServer =
  // 'https://rainbowcolors.conteir.no/snowstorm/snomed-ct'
  'https://snowstorm.terminologi.helsedirektoratet.no/snowstorm/snomed-ct' 

export const headers = {
  'Accept-Language': 'no-X-47351000202107,no-X-61000202103,no',
}

//Semantic API queries
export const codeSystem = 'snomed'
