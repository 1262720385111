// KI-assist-api.jsx

const chatServer = process.env.REACT_APP_API_LAYER
const authorizationToken = process.env.REACT_APP_API_KEY

export const createThread = async () => {
  try {
    const response = await fetch(`${chatServer}/start`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationToken}`,
      },
    })
    const data = await response.json()
    return data.thread_id
  } catch (error) {
    console.error('Could not create thread:', error)
    throw error
  }
}

export const sendMessage = async (threadId, messageContent) => {
  try {
    const response = await fetch(`${chatServer}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationToken}`,
      },
      body: JSON.stringify({
        thread_id: threadId,
        message: messageContent,
      }),
    })

    if (!response.body || !response.body.getReader) {
      throw new Error('Streaming not supported')
    }

    const reader = response.body.getReader()
    return new ReadableStream({
      async start(controller) {
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          controller.enqueue(value)
        }
        controller.close()
        reader.releaseLock()
      },
    })
  } catch (error) {
    console.error('Failed to send message:', error)
    throw error
  }
}
